export default [
    {
        name: 'ダッシュボード',
        url: '/admin-manager',
        icon: 'fa fa-tachometer'
    },
    {
        name: 'ゲーム管理',
        url: '/admin-manager/games',
        icon: 'fa fa-gamepad'
    },
    {
        name: '大会管理',
        url: '/admin-manager/events',
        icon: 'fa fa-calendar-o'
    },
    {
        name: '選手管理',
        url: '/admin-manager/players',
        icon: 'fa fa-users',
    },
    {
        name: '選手登録申請承認',
        url: '/admin-manager/send-requests',
        icon: 'fa fa-list-alt',
        meta: 'send-request',
    },
    {
        name: 'チーム管理',
        url: '/admin-manager/teams',
        icon: 'fa fa-handshake-o'
    },
    {
        name: 'ギフト券管理',
        url: '/admin-manager/cards',
        icon: 'fa fa-address-card-o'
    },
    {
        name: '広告管理',
        url: '/admin-manager/ads',
        icon: 'fa fa-youtube-play'
    },
    {
        name: 'ユーザ情報管理',
        url: '/admin-manager/users',
        icon: 'fa fa-user'
    },
    {
        name: 'システムパラメータ設定',
        url: '/admin-manager/sys-params',
        icon: 'fa fa-cog'
    },
]