<template>
  <div class="app page-bg admin-page">
    <AppHeader fixed class="justify-content-start">
      <SidebarToggler mobile class="d-lg-none" display="md" />
      <b-link class="navbar-brand" href="/">
        <esports-logo-header width="50" height="40" />
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" :defaultOpen="true" />
      <b-navbar-nav class="d-md-down-none">
        <b-nav-item class="px-3" href="/admin-manager">ダッシュボード</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-dropdown
          size="lg"
          variant="link"
          toggle-class="text-decoration-none"
          class="dropdown-account"
          no-caret
          menu-class="account-user"
          left
          toggle-tag="div"
        >
          <template #button-content>
            <b-avatar
              href="#foo"
              variant="primary"
              :text="letterName(user.username)"
              class="align-baseline"
              v-if="user"
            ></b-avatar>
            <span class="pl-2 d-md-down-none" v-if="user">{{ user.username }}</span>
          </template>
          <b-dropdown-item v-if="user && user.roleId === 1" href="/admin-manager">管理画面</b-dropdown-item>
          <b-dropdown-item @click="handleLogout">{{
            $t("general_inputs.btn_logout")
          }}</b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarNav :navItems="nav"></SidebarNav>
      </AppSidebar>
      <main class="container-page" :class="{ main: !isMb }">
        <b-container class="p-3" fluid>
          <router-view :key="$route.fullPath"></router-view>
        </b-container>
      </main>

      <esports-loading-button
        class="overlay-content-page"
        width="100"
        height="100"
        v-if="isLazyLoading"
      />

    </div>
    <AppFooter>
      <div>Powered by Donate eSports</div>
    </AppFooter>
  </div>
</template>

<script>
import {
  Header as AppHeader,
  Sidebar as AppSidebar,
  Footer as AppFooter,
  SidebarToggler,
  SidebarNav,
} from "@coreui/vue";
import navs from '@/utils/navs';
import { mapGetters } from "vuex";
import { EventBus } from '@/utils/event-bus';

export default {
  name: "AdminManagerTemplate",
  components: {
    AppHeader,
    AppSidebar,
    AppFooter,
    SidebarToggler,
    SidebarNav,
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "user", "isLazyLoading"]),
  },
  data() {
    return {
      nav: [],
      isMb: false,
      indexSendRequest: 0
    };
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  async mounted() {

    this.onResize();

    this.nav = navs;

    this.indexSendRequest = navs.findIndex(item => item.meta && item.meta === 'send-request');

    window.addEventListener("resize", this.onResize, { passive: true });
    
    if(!this.authenticated) {
      this.$store.commit('auth/clearSdk');
    }

    EventBus.$on('loadNumberRequests', (numb) => {
      this.nav[this.indexSendRequest].badge.text = numb || 'New';
    });
  },
  methods: {
    onResize() {
      this.isMb = window.innerWidth < 768;
    },
    async handleLogout() {
      await this.$store.dispatch('auth/logout');
      this.$router.push({ path: "/admin/login" });
      window.location.href = '/admin/login';
    },
  },
};
</script>
<style lang="scss">
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~@coreui/coreui-pro/scss/coreui";
@import "@/assets/scss/admin";
</style>